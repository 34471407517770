import React from 'react';
import { graphql } from 'gatsby';
import { ReactComponent as DownArrowSvg } from '../../assets/svgs/down-arrow.svg';
import { sanitizer } from '../../util';
import Section from '../Section/Section';

import './SectionBasedContent.scss';

const SectionBasedContent = ({ data: { title, sections } }) => {
  return (
    <Section className="SectionBasedContent">
      <h1 className="page-title">{title}</h1>

      <div className="content-wrapper">
        <aside className="sidenav">
          <span className="instructions">
            Jump to section <DownArrowSvg className="arrow-down" />
          </span>
          <ul className="links-container">
            {sections.map(({ id, jumpToLinkTitle, title }) => (
              <li key={id}>
                <a href={`#${id}`}>{jumpToLinkTitle || title}</a>
              </li>
            ))}
          </ul>
        </aside>
        <div className="copy-container">
          <h1 className="nested-page-title" aria-hidden="true">
            {title}
          </h1>
          {sections.map(({ id, title, content }) => (
            <section className="section" key={id} id={id}>
              <h3 className="section-title">{title}</h3>
              <div className="chapter-section section-data" dangerouslySetInnerHTML={{ __html: sanitizer(content) }} />
            </section>
          ))}
        </div>
      </div>
    </Section>
  );
};

export default SectionBasedContent;

export const fragments = graphql`
  fragment PrivacySectionBasedContent on WpPage_Flexiblelayout_FlexibleChildren_PrivacyPage {
    sections {
      content
      id
      jumpToLinkTitle
      name
      title
    }
    title
  }

  fragment PrivacySectionBasedContent_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_PrivacyPage {
    sections {
      content
      id
      jumpToLinkTitle
      name
      title
    }
    title
  }

  fragment SectionBasedContent on WpPage_Flexiblelayout_FlexibleChildren_SectionBasedContent {
    sections {
      content
      id
      jumpToLinkTitle
      name
      title
    }
    title
  }

  fragment SectionBasedContent_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_SectionBasedContent {
    sections {
      content
      id
      jumpToLinkTitle
      name
      title
    }
    title
  }
`;
